<template>
    <div v-loading.fullscreen.lock="loading">
        <storage-money-bill-search-bar-component
            output_auth="导出仓储费明细"
            :fAllClient="fAllClient"
            @search="get_storage_money"
            @output="output_storage_money"
        ></storage-money-bill-search-bar-component>
        <common-table-component
            :col_arr="fcol_arr"
            :table_data="ftable_data"
        ></common-table-component>
        <el-card>
            <el-row :gutter="20">
                <el-col :span="24">合计应收仓储费：<span class="collect_moneyc">{{this.reality_storage_money | diy_money}}</span></el-col>
            </el-row>
        </el-card>
        <common-page-component
            :current_page="cond.page"
            :page_size="cond.limit"
            :total="ftotal"
            @search="get_storage_money"
        ></common-page-component>
    </div>
</template>

<script>
import { storage_money_request} from '@/network/WareHouse/moneybill.js'
import { thousandBitSeparator,fomatFloat} from '@/assets/js/common.js'
import { client_list_request} from '@/network/list.js'

import StorageMoneyBillSearchBarComponent from '@/components/WareHouse/MoneyBill/StorageMoneyBillSearchBarComponent'
import CommonTableComponent from '@/components/common/CommonTableComponent'
import CommonPageComponent from '@/components/common/CommonPageComponent'

export default {
    name:'',
    data(){
        return {
            loading: true,
            cond: {
                client_id: '',
                product_name: '',
                order_num: '',
                state: '',
                date: '',
                page: 1,
                limit: 10,
            },
            ftotal: 0,
            ftable_data: [],
            fcol_arr: [
                {
                    prop: 'state',
                    label: '对账状态',
                    minWidth: '100px',
                    tag: (d) => {
                        if(d===0) {
                            return {
                                type: 'info',
                                filter_data: '未对账'
                            }
                        } else if(d===1) {
                            return {
                                filter_data: '对账中'
                            }
                        } else if(d===2) {
                            return {
                                type: 'success',
                                filter_data: '已对账'
                            }
                        }
                    }
                },
                {
                    prop: 'bill_date',
                    label: '库存日期',
                    minWidth: '100px'
                },
                {
                    prop: 'client_name',
                    label: '客户名称',
                    minWidth: '260px'
                },
                {
                    prop: 'product_name',
                    label: '产品名称',
                    minWidth: '300px'
                },
                {
                    prop: 'batch_number',
                    label: '订单号',
                    minWidth: '120px',
                },
                {
                    prop: 'weight',
                    label: '库存重量(KG)',
                    minWidth: '130px',
                    editdata(row) {
                        return thousandBitSeparator(fomatFloat(row['weight']))
                    }
                },
                {
                    prop: 'tray_num',
                    label: '托盘数',
                    minWidth: '100px',
                },
                {
                    prop: 'storage_money',
                    label: '应收仓储费(元)',
                    minWidth: '130px',
                    align: 'right',
                    html(row) {
                        return '<span style="color:#2828FF;">' + thousandBitSeparator(fomatFloat(row['storage_money'],4)) + '</span>'
                    }
                }
            ],
            reality_storage_money: 0,
            fAllClient: [],
            select_id: []
        }
    },
    computed:{},
    methods:{
        get_storage_money(param = {}) {
            this.loading = true
            this.cond.client_id = param.client_id ?? this.cond.client_id
            this.cond.product_name = param.product_name ?? this.cond.product_name
            this.cond.order_num = param.order_num ?? this.cond.order_num
            this.cond.state = param.state ?? this.cond.state
            this.cond.date = param.date ?? this.cond.date
            this.cond.page = param.page ?? this.cond.page
            if (param.limit) {
                this.cond.limit = param.limit
                this.cond.page = 1
            }

            storage_money_request(this.cond)
                .then((s) => {
                    this.loading = false
                    if (s.status === 0) {
                        this.ftable_data = s.result.data
                        this.ftotal = s.result.total
                        this.reality_storage_money = s.result.reality_storage_money
                    } else {
                        this.$message.error(s.msg)
                    }
                })
                .catch((err) => {
                    this.loading = false
                    this.$message.error(err)
                })
        },
        get_all_client() {
            client_list_request({})
                .then((s) => {
                    if (s.status === 0) {
                        this.fAllClient = s.result
                    } else {
                        this.$message.error(s.msg)
                    }
                })
                .catch((err) => {
                    this.loading = false
                    this.$message.error(err)
                })
        },
        output_storage_money(param = {}) {
            if(this.cond.date === '') {
                this.$message.error('请选择日期后再进行导出')
                return
            }
            const url = this.$store.state.baseURL 
            + 'money_bill/output_storage'
            + '?client_id=' + param.client_id
            + '&product_name=' + param.product_name
            + '&order_num=' + param.order_num
            + '&state=' + param.state
            + '&date[]=' + param.date[0]
            + '&date[]=' + param.date[1]
            
            window.open(url, '_blank')
        }
    },
    filters:{
        diy_money(v) {
            return '￥' + thousandBitSeparator(fomatFloat(v,4)) + "元"
        }
    },
    props:{},
    created(){
        this.get_storage_money()
        this.get_all_client()
    },
    destoryed(){},
    mounted(){},
    updated(){},
    components:{
        StorageMoneyBillSearchBarComponent,
        CommonTableComponent,
        CommonPageComponent
    },
    watch:{}
}
</script>

<style lang='less'>
.collect_moneyc {
    color: #00BB00;
}
</style>